import { Course } from "@cpe/models/course";
import { CourseDetailsResponseModel } from "@cpe/services/courses";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CourseDetails, SearchWebcast } from "./thunks";

export interface CoursesDetailsState {
  details: CourseDetailsResponseModel | undefined;
  courses: Course[] | undefined;
  certificateProgram: Record<string, Course>;
  loading: boolean;
  error: string | null;
}
export const initialState: CoursesDetailsState = {
  details: undefined,
  courses: undefined,
  certificateProgram: {},
  loading: false,
  error: null
};

export const courseDetailsSlice = createSlice({
  name: "courses",
  initialState: initialState,
  reducers: {
    registerCourseDetails: state => {
      if (state.details) {
        state.details.actions = state.details?.actions.filter(action => action !== "REGISTER");
        state.details.actions.push("UNREGISTER");
        state.details.state.registered = true;
      }
    },
    registerSessionCardWebcast: (state, action: PayloadAction<{ productId: string }>) => {
      if (state.courses && state.courses?.length > 0) {
        const courseIndex = state.courses.findIndex(course => course.productId === action.payload.productId);
        state.courses[courseIndex].actions = state.courses[courseIndex].actions.filter(action => action !== "REGISTER");
        state.courses[courseIndex].actions.push("UNREGISTER");
        state.courses[courseIndex].state.registered = true;
      }
    },
    registerCertificateProgram: (state, action: PayloadAction<{ productId: string }> | undefined) => {
      if (action?.payload?.productId) {
        if (state.certificateProgram[action.payload.productId]) {
          state.certificateProgram[action.payload.productId].actions = state.certificateProgram[
            action.payload.productId
          ].actions.filter(action => action !== "REGISTER");
          state.certificateProgram[action.payload.productId].actions.push("UNREGISTER");
          state.certificateProgram[action.payload.productId].state.registered = true;
        }
      }
    },
    unregisterCourseDetails: state => {
      if (state.details) {
        state.details.actions = state.details?.actions.filter(action => action !== "UNREGISTER");
        state.details.actions.push("REGISTER");
        state.details.state.registered = false;
      }
    },
    unregisterSessionCardCourseWebcast: (state, action: PayloadAction<{ productId: string }>) => {
      if (state.courses && state.courses?.length > 0) {
        const courseIndex = state.courses.findIndex(course => course.productId === action.payload.productId);
        state.courses[courseIndex].actions = state.courses[courseIndex].actions.filter(
          action => action !== "UNREGISTER"
        );
        state.courses[courseIndex].actions.push("REGISTER");
        state.courses[courseIndex].state.registered = false;
      }
    },
    unregisterCertificateProgram: (state, action: PayloadAction<{ productId: string }>) => {
      if (action?.payload?.productId) {
        if (state.certificateProgram[action.payload.productId]) {
          state.certificateProgram[action.payload.productId].actions = state.certificateProgram[
            action.payload.productId
          ].actions.filter(action => action !== "UNREGISTER");
          state.certificateProgram[action.payload.productId].actions.push("REGISTER");
          state.certificateProgram[action.payload.productId].state.registered = false;
        }
      }
    },
    joinWebcastCourseDetails: state => {
      if (state.details) {
        state.details.status = "IN_PROGRESS";
      }
    },
    joinSessionCardCourseWebcast: (state, action: PayloadAction<{ productId: string }>) => {
      if (state.courses && state.courses?.length > 0) {
        const courseIndex = state.courses.findIndex(course => course.productId === action.payload.productId);
        state.courses[courseIndex].status = "IN_PROGRESS";
      }
    },
    joinWebcastCertificateProgram: (state, action?: PayloadAction<{ productId: string }>) => {
      if (action?.payload?.productId) {
        if (state.certificateProgram[action.payload.productId]) {
          state.certificateProgram[action.payload.productId].status = "IN_PROGRESS";
        }
      }
    },
    toogleBookmarkCertificateProgram: (state, action: PayloadAction<{ productId: string }>) => {
      if (state.certificateProgram) {
        state.certificateProgram[action.payload.productId].state.saved =
          !state.certificateProgram[action.payload.productId].state.saved;
      }
    }
  },
  extraReducers: builder => {
    builder.addCase(CourseDetails.fulfilled, (state, action) => {
      const response = action.payload;

      if (response?.statusCode) {
        state.error = response?.message || "Something is wrong.";
        state.details = undefined;
      } else {
        if (response) {
          state.details = response;
          response.children &&
            response.children.map(cp => {
              state.certificateProgram[cp.productId] = cp;
            });
        }
        state.error = "";
      }
      state.loading = false;
    });
    builder.addCase(CourseDetails.rejected, state => {
      state.loading = false;
      state.error = "Something is wrong.";
    });
    builder.addCase(CourseDetails.pending, state => {
      state.error = "";
      state.loading = true;
    });
    builder.addCase(SearchWebcast.fulfilled, (state, action) => {
      const response = action.payload;

      if (response?.statusCode) {
        state.error = response?.message || "Something is wrong.";
        state.courses = undefined;
      } else {
        state.courses = action.payload?.courses;
        state.error = "";
      }
      state.loading = false;
    });
    builder.addCase(SearchWebcast.rejected, state => {
      state.loading = false;
      state.error = "Something is wrong.";
    });
    builder.addCase(SearchWebcast.pending, state => {
      state.error = "";
      state.loading = true;
    });
  }
});

export const {
  registerCourseDetails,
  registerSessionCardWebcast,
  registerCertificateProgram,
  unregisterCourseDetails,
  unregisterSessionCardCourseWebcast,
  unregisterCertificateProgram,
  joinWebcastCourseDetails,
  joinSessionCardCourseWebcast,
  joinWebcastCertificateProgram,
  toogleBookmarkCertificateProgram
} = courseDetailsSlice.actions;
export default courseDetailsSlice.reducer;
