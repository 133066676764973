import { TokenAuthenticator } from "./auth-token";

export interface ErrorResponse {
  statusCode: string;
  timestamp: number;
  message: string;
}
export interface HttpResponse<T> extends Response {
  parsedBody?: T & ErrorResponse;
}

export async function http<T>(request: RequestInfo): Promise<HttpResponse<T>> {
  const response: HttpResponse<T> = await fetch(request, {
    credentials: "include"
  });
  try {
    response.parsedBody = (await response?.json?.()) || undefined;
  } catch (error) {
    response.parsedBody = undefined;
  }

  return response;
}

export async function get<T>(path: string, args?: RequestInit): Promise<HttpResponse<T & ErrorResponse>> {
  const defaultArgs = {
    method: "get",
    headers: {
      "Content-Type": "application/json"
    }
  };
  let extendedArgs = { ...defaultArgs, ...args };
  if (window.__config__?.aiccToken) {
    const headers = {
      Authorization: `Bearer ${TokenAuthenticator.instance.authToken.token}`
    };
    extendedArgs = {
      ...extendedArgs,
      headers: {
        ...extendedArgs.headers,
        ...headers
      }
    };
  }
  return await http<T & ErrorResponse>(new Request(path, extendedArgs));
}

export async function post<T>(path: string, body: any, args?: RequestInit): Promise<HttpResponse<T & ErrorResponse>> {
  const defaultArgs = {
    method: "post",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json"
    }
  };
  let extendedArgs = { ...defaultArgs, ...args };
  if (window.__config__?.aiccToken) {
    const headers = {
      Authorization: `Bearer ${TokenAuthenticator.instance.authToken.token}`
    };
    extendedArgs = {
      ...extendedArgs,
      headers: {
        ...extendedArgs.headers,
        ...headers
      }
    };
  }
  return await http<T & ErrorResponse>(new Request(path, extendedArgs));
}

export async function Delete<T>(path: string, body: any, args?: RequestInit): Promise<HttpResponse<T & ErrorResponse>> {
  const defaultArgs = {
    method: "delete",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json"
    }
  };
  let extendedArgs = { ...defaultArgs, ...args };
  if (window.__config__?.aiccToken) {
    const headers = {
      Authorization: `Bearer ${TokenAuthenticator.instance.authToken.token}`
    };
    extendedArgs = {
      ...extendedArgs,
      headers: {
        ...extendedArgs.headers,
        ...headers
      }
    };
  }
  return await http<T & ErrorResponse>(new Request(path, extendedArgs));
}

export async function put<T>(path: string, body: any, args?: RequestInit): Promise<HttpResponse<T & ErrorResponse>> {
  const defaultArgs = {
    method: "put",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json"
    }
  };
  let extendedArgs = { ...defaultArgs, ...args };
  if (window.__config__?.aiccToken) {
    const headers = {
      Authorization: `Bearer ${TokenAuthenticator.instance.authToken.token}`
    };
    extendedArgs = {
      ...extendedArgs,
      headers: {
        ...extendedArgs.headers,
        ...headers
      }
    };
  }
  return await http<T & ErrorResponse>(new Request(path, extendedArgs));
}

const getFileNameFromContentDisposition = (headers: Headers): string => {
  return headers.get("content-disposition")?.split("filename=")[1].replace(/"/g, "") ?? "CPE file".replace(/"/g, "");
};
export async function getFile<T>(path: string, args?: RequestInit) {
  const defaultArgs = {
    method: "get",
    headers: {
      "Content-Type": "application/json"
    }
  };
  let extendedArgs = { ...defaultArgs, ...args };
  if (window.__config__?.aiccToken) {
    const headers = {
      Authorization: `Bearer ${TokenAuthenticator.instance.authToken.token}`
    };
    extendedArgs = {
      ...extendedArgs,
      headers: {
        ...extendedArgs.headers,
        ...headers
      }
    };
  }
  const response: HttpResponse<T> = await fetch(new Request(path, extendedArgs), {
    credentials: "include"
  });
  return response;
}
export async function downloadFile<T>(path: string, args?: RequestInit) {
  const defaultArgs = {
    method: "get",
    headers: {
      "Content-Type": "application/json"
    }
  };
  let extendedArgs = { ...defaultArgs, ...args };
  if (window.__config__?.aiccToken) {
    const headers = {
      Authorization: `Bearer ${TokenAuthenticator.instance.authToken.token}`
    };
    extendedArgs = {
      ...extendedArgs,
      headers: {
        ...extendedArgs.headers,
        ...headers
      }
    };
  }
  const response: HttpResponse<T> = await fetch(new Request(path, extendedArgs), {
    credentials: "include"
  });
  try {
    if (response?.status.toString().match(SuccessCodeRegex)) {
      download(response);
    }
  } catch (error) {
    response.parsedBody = undefined;
  }
  return response;
}

export async function download<T>(response: HttpResponse<T>) {
  if (response?.status.toString().match(SuccessCodeRegex)) {
    // 🔨 Workaround to download files on the same page as an async action.
    const fileName = getFileNameFromContentDisposition(response.headers);
    const a = document.createElement("a");
    const blob = await response?.blob?.();
    a.href = window.URL.createObjectURL(blob);
    a.download = fileName;
    a.click();
    a.remove();
  }
}

export const SuccessCodeRegex = /2[0-9]{2}/;
