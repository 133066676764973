import { CRMData, CRMSubmitData, EulaData, User, UserAiccData } from "@cpe/models/user";
import * as FetchUtil from "../fetch-util";
export class UsersService {
  static getUser(userId: string): Promise<FetchUtil.HttpResponse<User>> {
    return FetchUtil.get(`/api/v1/users/${userId}`);
  }
  static getCurrentUser(): Promise<FetchUtil.HttpResponse<User>> {
    return FetchUtil.get(`/api/v1/users/0?details=licenses`);
  }
  static getCRMData(): Promise<FetchUtil.HttpResponse<CRMData>> {
    return FetchUtil.get(`/api/v1/users/crmdata`);
  }
  static getEulaData(): Promise<FetchUtil.HttpResponse<EulaData>> {
    return FetchUtil.get(`/api/v1/users/euladata`);
  }
  static updateCRMData(data: Omit<CRMSubmitData, "notLicensed">): Promise<FetchUtil.HttpResponse<CRMData>> {
    return FetchUtil.put(`/api/v1/users/me/crmdata`, data);
  }
  static updateUserAiccData(data: UserAiccData): Promise<FetchUtil.HttpResponse<FetchUtil.ErrorResponse>> {
    return FetchUtil.put(`/api/v1/users/me/aicc`, data);
  }
}
