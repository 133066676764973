import { Authentication, TokenTypes } from "@cpe/services/authentication";
const RENEWAL_MARGIN = 900000;
export class AuthToken {
  _token: string;
  _refreshToken: string;
  _expiration: number;

  constructor(token: string, refreshToken: string, expiration: number) {
    this._token = token;
    this._refreshToken = refreshToken;
    this._expiration = expiration;
  }

  useRefreshToken() {
    return this.refreshToken && Date.now() >= this.expiration;
  }

  durationToRefreshToken() {
    const duration = this.expiration - Date.now() - RENEWAL_MARGIN;
    return duration < 0 ? 0 : duration;
  }

  get refreshToken(): string {
    return this._refreshToken;
  }

  set refreshToken(refreshToken: string) {
    this._refreshToken = refreshToken;
  }
  get token(): string {
    return this._token;
  }

  set token(token: string) {
    this._token = token;
  }
  get expiration(): number {
    return this._expiration;
  }

  set expiration(expiration: number) {
    this._expiration = expiration;
  }
}

export class TokenAuthenticator {
  private static _instance: TokenAuthenticator;

  /**
   * The Singleton's constructor should always be private to prevent direct
   * construction calls with the `new` operator.
   */
  private constructor() {}

  /**
   * The static getter that controls access to the singleton instance.
   *
   * This implementation allows you to extend the Singleton class while
   * keeping just one instance of each subclass around.
   */
  public static get instance(): TokenAuthenticator {
    if (!TokenAuthenticator._instance) {
      TokenAuthenticator._instance = new TokenAuthenticator();
      TokenAuthenticator._instance.refreshPermanentToken();
    }

    return TokenAuthenticator._instance;
  }

  /**
   * Finally, any singleton can define some business logic, which can be
   * executed on its instance.
   */
  public authToken: AuthToken = new AuthToken(window.__config__?.aiccToken || "", "", 0);

  public getNewToken = function (type: TokenTypes) {
    return TokenAuthenticator._instance.fetchToken(type, TokenAuthenticator._instance.authToken.token);
  };

  public refreshPermanentToken = async function () {
    const newTokenInfo = TokenAuthenticator._instance.authToken.useRefreshToken()
      ? await TokenAuthenticator._instance.refreshExpiredToken()
      : await TokenAuthenticator._instance.getNewToken(TokenTypes.PERMANENT);
    if (!newTokenInfo) return;
    TokenAuthenticator._instance.authToken = new AuthToken(
      newTokenInfo.token,
      newTokenInfo.refreshToken,
      newTokenInfo.expiration
    );

    setTimeout(
      TokenAuthenticator._instance.refreshPermanentToken,
      TokenAuthenticator._instance.authToken.durationToRefreshToken()
    );
  };

  public refreshExpiredToken = function () {
    return TokenAuthenticator._instance.fetchToken(
      TokenTypes.PERMANENT,
      TokenAuthenticator._instance.authToken.refreshToken
    );
  };

  public fetchToken = async function (type: TokenTypes, refreshToken: string) {
    const response = await Authentication.refreshAiccToken(type, refreshToken);

    const token = response.parsedBody;
    if (token) {
      return new AuthToken(token?.token, token?.refreshToken, token?.expiration);
    }

    return TokenAuthenticator._instance.authToken;
  };
}
