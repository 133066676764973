import { AicpaDeliveryMethod } from "@cpe/models/certificates";
import { Course, CourseFormats, SelectedTabModel } from "@cpe/models/course";
import * as FetchUtil from "../fetch-util";

export interface ReferenceMaterialsModel {
  name: string;
  extension: string;
  url?: string;
  availableBeforeEventStart?: boolean;
  availableDuringEvent?: boolean;
}

export interface CourseNote {
  id: number;
  excerpt: string;
  content: string; // max length: 64999
  created: string;
  updated: string;
  orderNumber: number;
  playbackTime?: number;
}

export interface CourseDetailsNotesModel {
  results: CourseNote[];
  total: number;
}

export interface CourseDetailsAttemptsModel {
  finishDate: string;
  scorePcnt: number;
  passed: boolean;
  certificateUrl?: string;
}
export interface CourseDetailsAggregatedSessionsModel {
  productId: string;
  startDate?: string;
  format: CourseFormats;
}

export interface CourseParent {
  productId: string;
  format: CourseFormats;
  name: string;
  numChildren: number;
}
export interface CourseDetailsResponseModel extends Course {
  code?: string;
  description: string; //HTML
  webBrowserNotice?: string;
  learningObjectives?: string; // HTML
  deliveryMethod: keyof AicpaDeliveryMethod;
  audioOnly?: boolean;
  duration?: number; // minutes
  publishDate?: string;
  revisionDate?: string;
  plannedExpiration?: string;
  courseLicenseExpiration?: string;
  courseExpired?: boolean;
  knowledgeLevel?: string;
  learnerLevels?: string[];
  imaCompetencies?: string[];
  industries?: string[];
  topics?: string[];
  advancedPreparation?: string; // HTML
  prerequisites?: string; // HTML
  regRequirements?: string; // HTML
  nasbaSponsorStatement?: string; // HTML
  isBeckerCpeSponsor?: boolean;
  accreditations?: {
    name: string;
    id: string;
    credits: number;
    approvedProgram?: {
      title: string;
      number: string;
    };
  }[];
  providers?: {
    name: string;
    statement: string;
    imageUrl?: string;
  }[];
  referenceMaterials?: ReferenceMaterialsModel[];
  parents?: CourseParent[];
  children?: Course[]; // for LEARNINGPATH and CERTIFICATE_PROGRAM courses
  completeChildrenInOrder?: boolean;
  aggregatedSessions?: CourseDetailsAggregatedSessionsModel[];
  lifecycles?: {
    name: string;
    color: string;
  }[];
  attempts?: CourseDetailsAttemptsModel[]; // TODO: remove this after the removal of COURSE_DETAILS_PAGE_NANO feature flag
  attemptsGroup?: {
    current: CourseDetailsAttemptsModel[];
    past: CourseDetailsAttemptsModel[];
  };
  participationCredits?: {
    // Available for Webcasts/Rebroadcasts
    markers?: {
      total: number;
      answered: number;
    };
    creditsAwarded: number;
  };
  compliance?: {
    missingAnyRequirement: boolean;
    licenses: {
      name: string;
      categories: string[];
    }[];
  };
  replacedFrom?: string;
  replacementTo?: string;
  courseMediaId: string;
  unansweredSurvey?: {
    title: string;
    url: string;
  };
  pilotTesting?: {
    periodStatus: "ONGOING" | "FINISHED";
    surveyStatus: "NO_SURVEY" | "UNANSWERED" | "COMPLETE";
  };
  hasReviews: boolean;
}

interface CourseDetailResponse {
  url: string;
}
interface CourseLaunchResponse {
  url: string;
}

export interface OptionsModel {
  value: string;
  label: string;
}
export interface FiltersModel {
  description: string;
  name: string;
  options: OptionsModel[];
  hidden: boolean;
  type: "SINGLE_SELECT" | "MULTI_SELECT" | "TOGGLE";
}
export interface TabsModel {
  description: string;
  name: SelectedTabModel;
  providerid?: number;
}

export interface CourseFiltersModel {
  filters: FiltersModel[];
  sorts: OptionsModel[];
  tabs: TabsModel[];
  preSelectedFilterOptions?: Record<string, PreSelectedFilter[]>;
}

interface PreSelectedFilter {
  filterName: string;
  selectedValues: string[];
}

export interface CoursesResponseModel {
  courses: Course[];
  total: number;
}

export interface CounterResponseModel {
  total: number;
}

export interface UpdateNoteModel {
  productId: string;
  noteId: string;
  content: string;
  playbackTime?: number;
}

export class CoursesService {
  static save(productId: string): Promise<FetchUtil.HttpResponse<void>> {
    return FetchUtil.post(coursesApiMatchers.save.replace(":productId", productId), {});
  }
  static unsave(productId: string): Promise<FetchUtil.HttpResponse<void>> {
    return FetchUtil.Delete(coursesApiMatchers.save.replace(":productId", productId), {});
  }
  static register(productId: string): Promise<FetchUtil.HttpResponse<void>> {
    return FetchUtil.post(`/api/v1/courses/${productId}/register`, {});
  }
  static unregister(productId: string): Promise<FetchUtil.HttpResponse<void>> {
    return FetchUtil.Delete(`/api/v1/courses/${productId}/register`, {});
  }
  static detail(productId: string): Promise<FetchUtil.HttpResponse<CourseDetailResponse>> {
    return FetchUtil.get(`/course/${productId}/detail`);
  }
  static launch(productId: string): Promise<FetchUtil.HttpResponse<CourseLaunchResponse>> {
    return FetchUtil.get(`/api/v1/courses/${productId}/launch`);
  }
  static present(productId: string): Promise<FetchUtil.HttpResponse<CourseLaunchResponse>> {
    return FetchUtil.get(`/api/v1/courses/${productId}/present`);
  }
  static filters(): Promise<FetchUtil.HttpResponse<CourseFiltersModel>> {
    return FetchUtil.get(`/api/v1/filters/course`);
  }
  static getCourses(search?: string): Promise<FetchUtil.HttpResponse<CoursesResponseModel>> {
    return FetchUtil.get(`/api/v1/courses${search}`);
  }
  static getCounter(url?: string): Promise<FetchUtil.HttpResponse<CounterResponseModel>> {
    return FetchUtil.get(`/api/v1/courses/count${url}`);
  }
  static hide(sectionId: string, productId: string): Promise<FetchUtil.HttpResponse<void>> {
    return FetchUtil.post(`/api/v1/sections/${sectionId}/courses/${productId}/hide`, {});
  }
  static getCourseDetails(productId: string): Promise<FetchUtil.HttpResponse<CourseDetailsResponseModel>> {
    return FetchUtil.get(`${coursesApiMatchers.details.replace(":productId", productId)}`, {});
  }
  static getNotes(productId: string, search?: string): Promise<FetchUtil.HttpResponse<CourseDetailsNotesModel>> {
    return FetchUtil.get(`${coursesApiMatchers.notes.replace(":productId", productId)}${search}`, {});
  }
  static updateNote({
    productId,
    noteId,
    content,
    playbackTime
  }: UpdateNoteModel): Promise<FetchUtil.HttpResponse<void>> {
    return FetchUtil.put(
      `${coursesApiMatchers.updateNote.replace(":productId", productId).replace(":noteId", noteId)}`,
      {
        content: content,
        playbackTime: playbackTime
      }
    );
  }
  static deleteNote(productId: string, noteId: string): Promise<FetchUtil.HttpResponse<void>> {
    return FetchUtil.Delete(
      `${coursesApiMatchers.updateNote.replace(":productId", productId).replace(":noteId", noteId)}`,
      {}
    );
  }
  static undoDeleteNote(productId: string, noteId: string): Promise<FetchUtil.HttpResponse<void>> {
    return FetchUtil.put(
      `${coursesApiMatchers.undoDeleteNote.replace(":productId", productId).replace(":noteId", noteId)}`,
      {}
    );
  }
  static downloadNotes(productId: string): Promise<FetchUtil.HttpResponse<void>> {
    return FetchUtil.get(`${coursesApiMatchers.downloadNotes.replace(":productId", productId)}`, {});
  }
  static downloadEvent(productId: string): Promise<FetchUtil.HttpResponse<void>> {
    return FetchUtil.downloadFile(`${coursesApiMatchers.downloadEvent.replace(":productId", productId)}`, {});
  }
  static addNote(
    productId: string,
    content: string,
    playbackTime?: number
  ): Promise<FetchUtil.HttpResponse<CourseDetailsNotesModel>> {
    return FetchUtil.post(
      `${coursesApiMatchers.notes.replace(":productId", productId)}`,
      playbackTime !== undefined && playbackTime !== null && playbackTime >= 0
        ? {
            content: content,
            playbackTime: playbackTime
          }
        : {
            content: content
          }
    );
  }
  static undoCompletion(productId: string): Promise<FetchUtil.HttpResponse<CourseDetailsResponseModel>> {
    return FetchUtil.Delete(`${coursesApiMatchers.completion.replace(":productId", productId)}`, {});
  }
  static complete(productId: string): Promise<FetchUtil.HttpResponse<CourseDetailsResponseModel>> {
    return FetchUtil.post(`${coursesApiMatchers.completion.replace(":productId", productId)}`, {});
  }
  static similarCourses(productId: string, accountExternalId?: string): Promise<FetchUtil.HttpResponse<Course[]>> {
    if (accountExternalId) {
      return FetchUtil.get(
        `${coursesApiMatchers.similarCourses.replace(":productId", productId)}?accountExternalId=${accountExternalId}`,
        {}
      );
    }
    return FetchUtil.get(`${coursesApiMatchers.similarCourses.replace(":productId", productId)}`, {});
  }
  static othersAlsoPlayedCourses(productId: string): Promise<FetchUtil.HttpResponse<Course[]>> {
    return FetchUtil.get(`${coursesApiMatchers.othersAlsoPlayedCourses.replace(":productId", productId)}`, {});
  }
  static searchWebcasts(search?: string): Promise<FetchUtil.HttpResponse<CoursesResponseModel>> {
    return FetchUtil.get(`${coursesApiMatchers.searchWebcasts}${search}`, {});
  }
  static gradeCourse(productId: string): Promise<FetchUtil.HttpResponse<void>> {
    return FetchUtil.post(coursesApiMatchers.gradeCourse.replace(":productId", productId), {});
  }
}

export const coursesApiMatchers = {
  details: "/api/v1/courses/:productId",
  completion: "/api/v1/courses/:productId/completion",
  save: "/api/v1/courses/:productId/save",
  notes: "/api/v1/notes/:productId",
  updateNote: "/api/v1/notes/:productId/:noteId",
  undoDeleteNote: "/api/v1/notes/:productId/:noteId/undo_delete",
  downloadNotes: "/api/v1/notes/:productId/download",
  downloadEvent: "/api/v1/courses/:productId/downloadEvent",
  similarCourses: "/api/v1/recommender/similar/:productId",
  othersAlsoPlayedCourses: "/api/v1/recommender/association/:productId",
  searchWebcasts: "/api/v1/courses/webcasts",
  gradeCourse: "/api/v1/courses/:productId/grade"
};
